define("discourse/plugins/chat/discourse/components/chat-channel-unread-indicator", ["exports", "@glimmer/component", "@ember/service", "discourse/helpers/concat-class", "discourse/plugins/chat/discourse/lib/chat-user-preferences", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _concatClass, _chatUserPreferences, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatChannelUnreadIndicator extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    get showUnreadIndicator() {
      return this.args.channel.tracking.unreadCount > 0 || this.args.channel.unreadThreadsCountSinceLastViewed > 0;
    }
    get urgentCount() {
      if (this.hasChannelMentions) {
        return this.args.channel.tracking.mentionCount;
      }
      if (this.hasWatchedThreads) {
        return this.args.channel.tracking.watchedThreadsUnreadCount;
      }
      return this.args.channel.tracking.unreadCount;
    }
    get isUrgent() {
      if (this.onlyMentions) {
        return this.hasChannelMentions;
      }
      return this.isDirectMessage || this.hasChannelMentions || this.hasWatchedThreads;
    }
    get isDirectMessage() {
      return this.args.channel.isDirectMessageChannel && this.args.channel.tracking.unreadCount > 0;
    }
    get hasChannelMentions() {
      return this.args.channel.tracking.mentionCount > 0;
    }
    get hasWatchedThreads() {
      return this.args.channel.tracking.watchedThreadsUnreadCount > 0;
    }
    get onlyMentions() {
      return (0, _chatUserPreferences.hasChatIndicator)(this.currentUser).ONLY_MENTIONS;
    }
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.showUnreadIndicator}}
          <div
            class={{concatClass
              "chat-channel-unread-indicator"
              (if this.isUrgent "-urgent")
            }}
          >
            <div class="chat-channel-unread-indicator__number">{{#if
                this.isUrgent
              }}{{this.urgentCount}}{{else}}&nbsp;{{/if}}</div>
          </div>
        {{/if}}
      
    */
    {
      "id": "zI3/vGUM",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"showUnreadIndicator\"]],[[[1,\"      \"],[10,0],[15,0,[28,[32,0],[\"chat-channel-unread-indicator\",[52,[30,0,[\"isUrgent\"]],\"-urgent\"]],null]],[12],[1,\"\\n        \"],[10,0],[14,0,\"chat-channel-unread-indicator__number\"],[12],[41,[30,0,[\"isUrgent\"]],[[[1,[30,0,[\"urgentCount\"]]]],[]],[[[1,\" \"]],[]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-channel-unread-indicator.js",
      "scope": () => [_concatClass.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatChannelUnreadIndicator;
});